<template>
  <v-container fluid>
    <v-card>
      <v-card-title>DASHBOARD</v-card-title>
      <!-- <v-card-text>
        <v-btn @click="showwhatapp = true">Whatsapp</v-btn>
      </v-card-text> -->
      <v-card-text v-if="$store.state.me.owner == 1 || $store.state.isadmin">
        <v-card>
          <v-card-title>SITUATION GLOBALE</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="3" md="3" class="text-left">
                <v-chip width="200" label large>
                  <h3>
                    {{ "Init : " + numberWithSpace(sit_init.toFixed(2)) }}
                  </h3>
                </v-chip>
              </v-col>
              <v-col cols="12" sm="3" md="3" class="text-left">
                <v-chip width="200" label large>
                  <h3>
                    Transfere :
                    <a @click="show_list(-1, null)">
                      {{ numberWithSpace(debit.toFixed(2)) }}
                    </a>
                  </h3>
                </v-chip>
              </v-col>
              <v-col cols="12" sm="3" md="3" class="text-center">
                <v-chip width="200" label large>
                  <h3>
                    Reçu :
                    <a @click="show_list(1, null)">
                      {{ numberWithSpace(credit.toFixed(2)) }}
                    </a>
                  </h3>
                </v-chip>
              </v-col>
              <v-col cols="12" sm="3" md="3" class="text-right">
                <v-chip
                  :color="
                    solde < 0
                      ? 'orange'
                      : solde > 0
                      ? 'light-green lighten-2'
                      : 'gray'
                  "
                  width="200"
                  label
                  large
                >
                  <h3>
                    {{ "Solde : " + numberWithSpace(solde.toFixed(2)) }}
                  </h3>
                </v-chip>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-card-text>
      <v-card-text v-if="$store.state.me.owner == 1 || $store.state.isadmin">
        <v-card>
          <v-card-title>OPERATIONS DU JOUR</v-card-title>
          <v-card-subtitle>{{ datefr($store.state.today) }}</v-card-subtitle>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="3" md="3" class="text-left">
                <v-chip width="200" label large>
                  <h3>
                    Vente :
                    <a @click="show_list(-1, null, '<>', $store.state.today)">{{
                      numberWithSpace(vente.toFixed(2))
                    }}</a>
                  </h3>
                </v-chip>
              </v-col>
              <v-col cols="12" sm="3" md="3" class="text-left">
                <v-chip width="200" label large>
                  <h3>
                    Achat :
                    <a @click="show_list(-1, null, '<>', $store.state.today)">{{
                      numberWithSpace(achat.toFixed(2))
                    }}</a>
                  </h3>
                </v-chip>
              </v-col>
              <v-col cols="12" sm="3" md="3" class="text-center">
                <v-chip width="200" label large>
                  <h3>
                    Reçu :
                    <a @click="show_list(1, null, '=', $store.state.today)">
                      {{ numberWithSpace(credit_day.toFixed(2)) }}</a
                    >
                  </h3>
                </v-chip>
              </v-col>
              <v-col cols="12" sm="3" md="3" class="text-right">
                <v-chip width="200" label large>
                  <h3>
                    Transfere :
                    <a @click="show_list(-1, null, '=', $store.state.today)">{{
                      numberWithSpace(debit_day.toFixed(2))
                    }}</a>
                  </h3>
                </v-chip>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="12">
                <alertes></alertes>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-card-text>
      <v-card-text>
        <v-card>
          <v-card-title>MA SITUATION</v-card-title>
          <v-card-subtitle>{{ $store.state.me.name }}</v-card-subtitle>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="3" md="3" class="text-left">
                <v-chip width="200" label large>
                  <h3>
                    {{
                      "Initial :" +
                      numberWithSpace($store.state.me.sit_init.toFixed(2))
                    }}
                  </h3>
                </v-chip>
              </v-col>
              <v-col cols="12" sm="3" md="3" class="text-left">
                <v-chip width="200" label large>
                  <h3>
                    Transfere :
                    <a @click="show_list(-1, $store.state.me.id)">{{
                      numberWithSpace($store.state.me.debit.toFixed(2))
                    }}</a>
                  </h3>
                </v-chip>
              </v-col>
              <v-col cols="12" sm="3" md="3" class="text-center">
                <v-chip width="200" label large>
                  <h3>
                    Reçu :
                    <a @click="show_list(1, $store.state.me.id)">
                      {{
                        numberWithSpace($store.state.me.credit.toFixed(2))
                      }}</a
                    >
                  </h3>
                </v-chip>
              </v-col>
              <v-col cols="12" sm="3" md="3" class="text-right">
                <v-chip width="200" label large>
                  <h3>
                    {{
                      "Solde : " +
                      numberWithSpace($store.state.me.solde.toFixed(2))
                    }}
                  </h3>
                </v-chip>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="12">
                <alertes></alertes>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
    <template>
      <WhatsAppForm :showForm="showwhatapp" @close="showwhatapp = false" />
    </template>

    <formlist
      :title="title"
      :list="trans_list"
      :showform="!isListClose"
      :listheaders="listheaders"
      :key="fl"
      @close="closeListForm"
      :width="width"
      :Total="true"
      :master="true"
      :kid="'id'"
      :orientation="orientation"
    >
    </formlist>
  </v-container>
</template>

<script>
import STAT from "../graphql/Trans/STAT.gql";
import USER_TRANSACTION from "../graphql/Stat/USER_TRANSACTION.gql";
export default {
  components: {
    alertes: () => import("../components/Widgets/Alertes.vue"),
    WhatsAppForm: () => import("../components/WhatsAppForm.vue"),
    formlist: () => import("../components/FormList.vue"),
  },
  data() {
    return {
      aff1: 1,
      klist: 0,
      orientation: "p",
      width: "1000px",
      title: "",
      isListClose: true,
      fl: 200,
      trans_list: [],
      listheaders: [
        {
          text: "Code",
          align: "start",
          value: "no_opr",
          selected: true,
          index: 0,
        },
        {
          text: "Operateur",
          align: "start",
          value: "tier_name",
          selected: true,
          index: 1,
        },

        {
          text: "Date Opr",
          value: "opr_date",
          selected: true,
          slot: "date",
          index: 3,
        },
        {
          text: "Date Max",
          value: "date_limite",
          selected: false,
          slot: "date",
          expire: true,
          index: 3,
        },
        {
          text: "Montant",
          value: "montant",
          selected: false,
          slot: "cur",
          align: "end",
          index: 4,
        },
        {
          text: "Monnaie",
          value: "currency",
          selected: false,
          index: 5,
        },

        {
          text: "Montant DA",
          value: "mont_dz",
          total: "mont_dz",
          selected: true,
          slot: "cur",
          align: "end",
          index: 6,
        },
        {
          text: "Mode",
          value: "type_paye",
          selected: true,
          index: 7,
        },

        {
          text: "Autre",
          value: "autre",
          slot: "chip",
          color: "autre_color",
          selected: true,
          index: 9,
        },
        {
          text: "Status",
          value: "status_name",

          slot: "chip",
          color: "status_color",
          selected: true,
          index: 10,
        },
      ],
      stat: [],
      sit_init: 0,
      debit: 0,
      credit: 0,
      solde: 0,
      vente: 0,
      achat: 0,
      debit_day: 0,
      credit_day: 0,
      showwhatapp: false,
    };
  },
  async mounted() {
    let r = await this.requette(STAT);
    if (r) {
      if (r.situation.length > 0) {
        this.sit_init = r.situation[0].sit_init;
        this.debit = r.situation[0].debit;
        this.credit = r.situation[0].credit;
        this.solde = r.situation[0].solde;

        this.vente = r.operations[0].debit_dev;
        this.credit = r.operations[0].credit_dev;
        this.debit_day = r.operations[0].debit_dz;
        this.credit_day = r.operations[0].credit_dz;
      }
    }
  },
  watch: {},
  methods: {
    datefr(date, time = false) {
      let t = "";
      let option = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      if (time)
        option = {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        };
      if (date) {
        var result = new Date(date);
        t = result.toLocaleDateString("fr-FR", option);
      }
      return t;
    },
    closeListForm() {
      this.isListClose = true;
    },
    async show_list(sens, user_id, dev, opr_date) {
      let r = await this.requette(USER_TRANSACTION, {
        user_id: user_id,
        sens: sens,
        dev: dev,
        opr_date: opr_date,
      });
      if (r) {
        this.trans_list = r.user_transactions;
        this.title =
          sens == 1 ? this.listheaders[7].text : this.listheaders[6].text;
        this.isListClose = false;
        this.fl++;
      }
    },
    async requette(query, v) {
      let r;
      this.loading = true;
      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          r = data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    numberWithSpace: function (x) {
      return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ");
    },
  },
};
</script>

<style>
#Mychart {
  width: 100%;
  height: 400px;
}
</style>
